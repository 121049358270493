<template>
  <div v-if="item.state !== 1 && item.state !== 7">
    <!--                    <a @click.prevent href="#" class="p-link table-link-icon"><i class="ti-printer"></i></a>-->
    <div class="p-d-flex p-ai-center" :class="item.link_opened_at ? 'p-jc-between' : 'p-jc-start'" v-if="item.sent_at">
      <!--                      <div v-if="item.state !== 1 && item.state !== 7" class="p-d-flex p-ai-center p-jc-center" style="opacity: 0.2">-->
      <div v-tooltip.top="generateMessageTooltip(item)" class="p-p-1 p-mr-1" :class="{'theme-color':!!item.message_read_at}" style="font-size: 1.1em">
<!--      <div v-tooltip.top="generateMessageTooltip(item)" class="p-p-1" :class="{'theme-color':!!item.message_read_at}" style="font-size: 1.1em">-->
        <i class="ti-email"></i>
      </div>
      <!--                      <div v-if="item.state !== 1 && item.state !== 7" class="p-d-flex p-ai-center p-jc-center" style="opacity: 0.2">-->
      <div v-if="!!item.link_opened_at" v-tooltip.top="generateLinkTooltip(item)" class="p-p-1" :class="{'theme-color':!!item.link_viewed_at}" style="font-size: 1.1em">
        <i class="ti-eye"></i>
      </div>
    </div>
  </div>
</template>

<script>
import formatMixins from "@/mixins/formatMixins";

export default {
  mixins: [ formatMixins ],
  name: 'OfferHistoryCell',
  props: {
    item: Object
  },
  methods: {
    generateMessageTooltip(item) {
      let str = ''
      if (item.message_read_at) {
        str += 'Message read at: ' + this.formatDate(item.message_read_at) + '\n'
      }
      if (item.sent_at) {
        str += 'Message sent at: ' + this.formatDate(item.sent_at) + '\n'
      }
      return str
    },
    generateLinkTooltip(item) {
      let str = ''
      if (item.link_viewed_at) {
        str += 'Link viewed at: ' + this.formatDate(item.link_viewed_at) + '\n'
      }
      if (item.link_opened_at) {
        str += 'Link opened at: ' + this.formatDate(item.link_opened_at) + '\n'
      }
      return str
    },
  }
}
</script>

<style scoped>

</style>